export const compulsoryColumns = [
  "ID",
  "Appointment Date",
  "Student/Learner ID",
];

export const compulsoryKeywords = [
  "Mental Health Presenting Issues that",
  "(PI1)",
  "Primary Mental Health Concern for the student",
  "(PC1)",
  "Contributing Factors that",
  "(CF1)",
  "any Diagnosed Mental Health or Neurodiverse Conditions",
  "(DC/MH1)",
  "Severity",
];

export const recognisedColumns = [
  "ID",
  "Name",
  "Email",
  "Appointment Date",
  "Support Team or Service",
  "Appointment Type",
  "Campus",
  "Student/Learner ID",
  "Linked to Case ID",
  "Student/Learner's Date of Birth",
  "Student/Learner's Gender Identity",
  "Student/Learner's Ethnicity",
  "Student/Learner's Sexual Orientation",
  "Health Board of Term Time Address",
];

export const recognisedKeyword = [
  "Mental Health Presenting Issues that",
  "(PI1)",
  "Primary Mental Health Concern for the student",
  "(PC1)",
  "Contributing Factors that",
  "(CF1)",
  "any Diagnosed Mental Health or Neurodiverse Conditions",
  "(DC/MH1)",
  "Severity",
];
export const dateColumns = [
  "Appointment Date",
  "Student/Learner's Date of Birth",
  "Start time",
  "Completion time",
  "Last modified time",
];
