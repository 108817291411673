import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPresignedURL as fetchPresignedURLApiCall,
  uploadOnPresignedURL as uploadOnPresignedURLApiCall,
  getValidationResults as getValidationResultsApiCall,
  getDuplicationResults as getDuplicationResultsApiCall,
  getSubmissions as getSubmissionsApiCall,
  submitMissingData as submitMissingDataApiCall,
  saveMissingData as saveMissingDataApiCall,
  submitDuplicatesResolution as submitDuplicatesResolutionApiCall,
} from "./api";

const initialAuthState = {
  userAttributes: null,
  presignedUploadUrl: null,
  fileValidationResults: [],
  isFetchingValidationResults: false,
  fileDuplicationResults: [],
  isFetchingDuplicationResults: false,
  submissions: [],
  isUploading: false,
  isFetchingSubmissions: false,
  isSubmittingMissingData: false, // Add new state
  isSubmittingDuplicateResolution: false,
};

const sevresSlice = createSlice({
  name: "sevres",
  initialState: initialAuthState,
  reducers: {
    saveUserAttributes: (state, action) => {
      state.userAttributes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignedUrl.pending, (state, action) => {
        state.isUploading = true;
      })
      .addCase(fetchSignedUrl.fulfilled, (state, action) => {
        state.presignedUploadUrl = action?.payload;
        state.isUploading = false;
      })
      .addCase(uploadOnPreSignedUrl.pending, (state, action) => {
        state.isUploading = false;
      })
      .addCase(uploadOnPreSignedUrl.fulfilled, (state, action) => {
        state.isUploading = false;
      })
      .addCase(getValidationResults.pending, (state, action) => {
        state.isFetchingValidationResults = true;
      })
      .addCase(getValidationResults.fulfilled, (state, action) => {
        state.fileValidationResults = action?.payload;
        state.isFetchingValidationResults = false;
      })
      .addCase(getDuplicationResults.pending, (state, action) => {
        state.isFetchingDuplicationResults = true;
      })
      .addCase(getDuplicationResults.fulfilled, (state, action) => {
        state.fileDuplicationResults = action?.payload;
        state.isFetchingDuplicationResults = false;
      })
      .addCase(getSubmissions.pending, (state, action) => {
        state.isFetchingSubmissions = true;
      })
      .addCase(getSubmissions.fulfilled, (state, action) => {
        state.submissions = action?.payload;
        state.isFetchingSubmissions = false;
      })
      // Add cases for submitMissingData
      .addCase(submitMissingData.pending, (state, action) => {
        state.isFetchingSubmissions = true;
        state.isSubmittingMissingData = true;
      })
      .addCase(submitMissingData.fulfilled, (state, action) => {
        state.isFetchingSubmissions = false;
        state.isSubmittingMissingData = false;
      })
      .addCase(submitMissingData.rejected, (state, action) => {
        state.isSubmittingMissingData = false;
      })
      // Add cases for saveMissingData
      .addCase(saveMissingData.pending, (state, action) => {
        state.isSubmittingMissingData = true;
      })
      .addCase(saveMissingData.fulfilled, (state, action) => {
        state.isSubmittingMissingData = false;
      })
      .addCase(saveMissingData.rejected, (state, action) => {
        state.isSubmittingMissingData = false;
      })
      // Add cases for submitDuplicateData
      .addCase(submitDuplicatesResolution.pending, (state, action) => {
        state.isFetchingSubmissions = true;
        state.isSubmittingDuplicateResolution = true;
      })
      .addCase(submitDuplicatesResolution.fulfilled, (state, action) => {
        state.isFetchingSubmissions = false;
        state.isSubmittingDuplicateResolution = false;
      })
      .addCase(submitDuplicatesResolution.rejected, (state, action) => {
        state.isSubmittingDuplicateResolution = false;
      });
  },
});

export const { saveUserAttributes } = sevresSlice.actions;

export const fetchSignedUrl = createAsyncThunk(
  "sevres/getPresignedUrl",
  async (formData, thunkAPI) => {
    const response = await fetchPresignedURLApiCall(formData);
    return JSON.parse(response.data.body);
  }
);

export const uploadOnPreSignedUrl = createAsyncThunk(
  "sevres/uploadOnPreSignedUrl",
  async (file, thunkAPI) => {
    const state = thunkAPI.getState().sevres;
    const url = state?.presignedUploadUrl?.presigned_url;
    const response = await uploadOnPresignedURLApiCall(file, url);
    return JSON.parse(response.data.body);
  }
);

export const getValidationResults = createAsyncThunk(
  "sevres/getValidationResults",
  async (formData, thunkAPI) => {
    const response = await getValidationResultsApiCall(formData);
    return response.data.body;
  }
);

export const getDuplicationResults = createAsyncThunk(
  "sevres/getDuplicationResults",
  async (formData, thunkAPI) => {
    const response = await getDuplicationResultsApiCall(formData);
    return response.data.body;
  }
);

export const getSubmissions = createAsyncThunk("sevres/getSubmissions", async (thunkAPI) => {
  const response = await getSubmissionsApiCall();
  return JSON.parse(response.data.body);
});

// Add the new thunk for submitting missing data
export const submitMissingData = createAsyncThunk(
  "sevres/submitMissingData",
  async (formData, thunkAPI) => {
    const response = await submitMissingDataApiCall(formData);
    return response.data;
  }
);

export const saveMissingData = createAsyncThunk(
  "sevres/saveMissingData",
  async (formData, thunkAPI) => {
    const response = await saveMissingDataApiCall(formData);
    return response.data;
  }
);

export const submitDuplicatesResolution = createAsyncThunk(
  "sevres/submitDuplicatesResolution",
  async (formData, thunkAPI) => {
    const response = await submitDuplicatesResolutionApiCall(formData);
    return response.data;
  }
);

export default sevresSlice.reducer;
