import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import editUploadDataTable from "./data/editUploadDataTable";

function EditUpload() {
  const { fileId } = useParams();
  const { isFetchingValidationResults, fileValidationResults } = useSelector(
    (state) => state.sevres
  );

  const editTableData = editUploadDataTable(
    fileValidationResults?.missing_values ? JSON.parse(fileValidationResults.missing_values) : [],
    fileValidationResults
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="secondary"
                  borderRadius="lg"
                  coloredShadow="secondary"
                >
                  {isFetchingValidationResults ? (
                    <CircularProgress />
                  ) : (
                    <MDTypography variant="h6" color="white">
                      Edit File:{" "}
                      {fileValidationResults ? fileValidationResults?.upload_file_name : fileId}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox p={2}>
                  {isFetchingValidationResults ? (
                    <CircularProgress />
                  ) : (
                    editTableData.rows.map((row, index) => <div key={index}>{row.details}</div>)
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default EditUpload;
