import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EditDuplicateRecords from "./edit-duplicate-records";
import EditDuplicateRows from "./edit-duplicate-rows";
import { submitDuplicatesResolution } from "store/sevres";

function DuplicateUpload() {
  const { fileId } = useParams();
  const { fileDuplicationResults, isFetchingDuplicationResults, isSubmittingDuplicateResolution } =
    useSelector((state) => state.sevres);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [duplicationResults, setDuplicationResults] = useState(
    fileDuplicationResults?.duplicate_data
      ? JSON.parse(fileDuplicationResults.duplicate_data).map((result) => ({
          ...result,
          keepNew: true,
          keepExisting: true,
          keepBoth: false,
        }))
      : []
  );

  const [duplicationRowResults, setDuplicationRowResults] = useState(
    fileDuplicationResults?.duplicate_within_file_data
      ? JSON.parse(fileDuplicationResults.duplicate_within_file_data).map((result) => ({
          ...result,
          keep: true,
        }))
      : []
  );

  const handleSubmit = async () => {
    const esRecordsMap = duplicationResults
      .filter((item) => item.source === "es")
      .reduce((acc, item) => {
        acc[item["Student / Learner ID"]] = {
          keepNew: item.keepNew,
          keepExisting: item.keepExisting,
          keepBoth: item.keepBoth,
        };
        return acc;
      }, {});

    // Update "upload" source objects with matching values from the map
    const results = duplicationResults.map((item) => {
      if (item.source === "upload" && esRecordsMap[item["Student / Learner ID"]]) {
        return {
          ...item,
          ...esRecordsMap[item["Student / Learner ID"]],
        };
      }
      return item;
    });

    const finalData = results.map((item) => ({
      id: item["_id"],
      source: item.source,
      keepNew: item.keepNew,
      keepExisting: item.keepExisting,
      keepBoth: item.keepBoth,
    }));

    const finalRowData = duplicationRowResults.map((item) => ({
      id: item["_id"],
      keep: item.keep,
    }));
    const submissionData = {
      fileId: fileDuplicationResults.file_id,
      resolvedData: finalData,
      resolvedRowData: finalRowData,
    };
    await dispatch(submitDuplicatesResolution(submissionData));
    navigate(`/upload`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="secondary"
            >
              {isFetchingDuplicationResults ? (
                <CircularProgress />
              ) : (
                <>
                  <MDTypography variant="h5" color="white">
                    Resolve Duplicates in File:{" "}
                    {fileDuplicationResults ? fileDuplicationResults.upload_file_name : fileId}
                  </MDTypography>
                </>
              )}
            </MDBox>
            {isFetchingDuplicationResults ? (
              <CircularProgress />
            ) : (
              <>
                {fileDuplicationResults?.duplicate_within_file_data && (
                  <EditDuplicateRows
                    title={"Click here to expand - Duplicate rows in submitted file - Selection Required"}
                    duplicationResults={duplicationRowResults}
                    setDuplicationResults={setDuplicationRowResults}
                  />
                )}

                {fileDuplicationResults?.duplicate_data && (
                  <EditDuplicateRecords
                    title={"Click here to expand - SevRes already has matching record - Selection Required"}
                    duplicationResults={duplicationResults}
                    setDuplicationResults={setDuplicationResults}
                  />
                )}

                <MDBox display="flex" justifyContent="center" p={5}>
                  {isSubmittingDuplicateResolution ? (
                    <CircularProgress />
                  ) : (
                    <MDButton variant="contained" color="info" onClick={handleSubmit}>
                      Submit
                    </MDButton>
                  )}
                </MDBox>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DuplicateUpload;
