/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput"; // Import MDInput component
import Select from "@mui/material/Select"; // Import Select component
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem component
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import MDButton from "components/MDButton"; // Import MDButton component
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { submitMissingData, saveMissingData } from "./../../../store/sevres";
import { Accordion, AccordionSummary, AccordionDetails, Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function data(results, file) {
  const { isSubmittingMissingData } = useSelector((state) => state.sevres);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Sort results by "Appointment Date" in descending order
  const sortedResults = results
    .sort((a, b) => {
      const dateA = new Date(a["Appointment Date"].split("/").reverse().join("-"));
      const dateB = new Date(b["Appointment Date"].split("/").reverse().join("-"));
      return dateB - dateA;
    })
    .map((result) => ({
      ...result,
    }));

  const groupedResults = sortedResults.reduce((acc, result, index) => {
    const key = `${result["Student / Learner ID"]}-${result["Practitioner Name"]}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push({ ...result, index });
    return acc;
  }, {});

  // Default to empty array
  const [formData, setFormData] = useState(
    sortedResults.map((result) => {
      // let selectedAction = "0";
      // const otherTextColumn = result["Which other text column?"];
      // if (otherTextColumn.includes("Primary") || otherTextColumn.includes("Presenting")) {

      // } else if (otherTextColumn.includes("Contributing")) {
      //   selectedAction = "low_freq_contrib";
      // } else if (otherTextColumn.includes("Diagnosed")) {
      //   selectedAction = "low_freq_condition";
      // }
      return {
        ...result,
        selectedAction: result["selectedAction"] || "0",
        reasonForSelection: result["reasonForSelection"] || "",
        otherTextValue: result["Other Text Value"] || "",
      };
    })
  );

  const handleInputChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };

  const isFormComplete = formData.every(
    (data) => data.selectedAction !== "0" && data.reasonForSelection
    // && data.otherTextValue
  );

  const handleUpload = async () => {
    const strippedFormData = {
      fileId: file?.file_id,
      data: formData,
    };
    await dispatch(submitMissingData(strippedFormData));
    navigate(`/upload`);
  };

  const handleSave = async () => {
    const strippedFormData = {
      fileId: file?.file_id,
      data: formData,
    };
    await dispatch(saveMissingData(strippedFormData));
  };

  return {
    columns: [
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Source ID
          </MDTypography>
        ),
        accessor: "sourceId",
        width: "10%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Student / Learner ID
          </MDTypography>
        ),
        accessor: "studentLearnerId",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Case No
          </MDTypography>
        ),
        accessor: "linkedToCaseId",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Appointment Date
          </MDTypography>
        ),
        accessor: "appointmentDate",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Practitioner Name
          </MDTypography>
        ),
        accessor: "practitionerName",
        width: "15%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Which Reason Code
          </MDTypography>
        ),
        accessor: "otherTextColumn",
        width: "20%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            What else selected
          </MDTypography>
        ),
        accessor: "elseSelected",
        width: "20%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Free Text
          </MDTypography>
        ),
        accessor: "otherTextValue",
        width: "20%",
        align: "left",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Selected Category
          </MDTypography>
        ),
        accessor: "selectedAction",
        width: "10%",
        align: "center",
      },
      {
        Header: (
          <MDTypography variant="h6" fontWeight="bold">
            Reason for Selection
          </MDTypography>
        ),
        accessor: "reasonForSelection",
        width: "10%",
        align: "center",
      },
    ],

    rows: Object.keys(groupedResults)
      .map((key, groupIndex) => {
        const group = groupedResults[key];
        const header = group[0];
        const isGroupComplete = group.every(
          (result) =>
            formData[result.index] &&
            formData[result.index].selectedAction &&
            formData[result.index].selectedAction !== "0" &&
            formData[result.index].reasonForSelection
          // formData[result.index].otherTextValue
        );

        return {
          studentLearnerId: header["Student / Learner ID"],
          practitionerName: header["Practitioner Name"],
          details: (
            <MDBox p={1}>
              <Accordion
                key={groupIndex}
                style={{ backgroundColor: isGroupComplete ? "#D6E4F9" : "#F9D6D5" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MDBox display="flex" flexDirection="column">
                    <Grid item xs={12} pb={2}>
                      <MDTypography component="h5" fontWeight="regular" variant="p" color="text">
                        <strong>Student / Learner ID:</strong> {header["Student / Learner ID"]}
                      </MDTypography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <MDTypography component="h5" fontWeight="regular" variant="p" color="text">
                          <strong>Practitioner Name:</strong> {header["Practitioner Name"]}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDTypography component="h5" fontWeight="regular" variant="p" color="text">
                          <strong>Appointment Date:</strong> {header["Appointment Date"]}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDTypography component="h5" fontWeight="regular" variant="p" color="text">
                          <strong>Case No:</strong> {header["Linked to Case ID"]}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDTypography component="h5" fontWeight="regular" variant="p" color="text">
                          <strong>Source ID:</strong> {header["Source ID"]}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </AccordionSummary>
                <AccordionDetails>
                  <Card>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                              <tr style={{ backgroundColor: "#D1D5DB" }}>
                                <th
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                    width: "12.5%",
                                  }}
                                >
                                  <MDTypography
                                    component="h5"
                                    fontWeight="medium"
                                    variant="p"
                                    color="dark"
                                  >
                                    Which Reason Code
                                  </MDTypography>
                                </th>
                                <th
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                    width: "31.25%",
                                  }}
                                >
                                  <MDTypography
                                    component="h5"
                                    fontWeight="medium"
                                    variant="p"
                                    color="dark"
                                  >
                                    What else selected
                                  </MDTypography>
                                </th>
                                <th
                                  style={{ padding: "8px", border: "1px solid #ddd", width: "25%" }}
                                >
                                  <MDTypography
                                    component="h5"
                                    fontWeight="medium"
                                    variant="p"
                                    color="dark"
                                  >
                                    Free Text
                                  </MDTypography>
                                </th>
                                <th
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                    width: "15.625%",
                                  }}
                                >
                                  <MDTypography
                                    component="h5"
                                    fontWeight="medium"
                                    variant="p"
                                    color="dark"
                                  >
                                    Selected Category
                                  </MDTypography>
                                </th>
                                <th
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                    width: "15.625%",
                                  }}
                                >
                                  <MDTypography
                                    component="h5"
                                    fontWeight="medium"
                                    variant="p"
                                    color="dark"
                                  >
                                    Reason for Selection
                                  </MDTypography>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {group.map((result) => (
                                <tr key={result.index}>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #ddd",
                                      width: "12.5%",
                                    }}
                                  >
                                    <MDTypography
                                      component="h5"
                                      fontWeight="regular"
                                      variant="p"
                                      color="text"
                                    >
                                      {result["Which other text column?"]}
                                    </MDTypography>
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #ddd",
                                      width: "31.25%",
                                    }}
                                  >
                                    <MDTypography
                                      component="h5"
                                      fontWeight="regular"
                                      variant="p"
                                      color="text"
                                    >
                                      {result["What else selected"]}
                                    </MDTypography>
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #ddd",
                                      width: "25%",
                                    }}
                                  >
                                    <MDTypography
                                      component="h5"
                                      fontWeight="regular"
                                      variant="p"
                                      color="text"
                                    >
                                      {result["Other Text Value"]}
                                    </MDTypography>
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #ddd",
                                      maxWidth: "200px",
                                      width: "15.625%",
                                    }}
                                  >
                                    <Select
                                      value={formData[result.index].selectedAction || "0"}
                                      onChange={(e) =>
                                        handleInputChange(
                                          result.index,
                                          "selectedAction",
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                    >
                                      <MenuItem value="0" disabled>
                                        Select an action
                                      </MenuItem>
                                      <MenuItem value="genuine">Genuine &quot;Other&quot;</MenuItem>
                                      <MenuItem value="inappropriate_use">
                                        Inappropriate use of the &quot;Other&quot; box
                                      </MenuItem>
                                      <MenuItem value="tbd">
                                        To be discussed with staff member
                                      </MenuItem>
                                    </Select>
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #ddd",
                                      width: "15.625%",
                                    }}
                                  >
                                    <MDInput
                                      value={formData[result.index].reasonForSelection}
                                      onChange={(e) =>
                                        handleInputChange(
                                          result.index,
                                          "reasonForSelection",
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          ),
        };
      })
      .concat({
        studentLearnerId: "",
        practitionerName: "",
        details: (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            {isSubmittingMissingData ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleUpload}
                    disabled={!isFormComplete}
                  >
                    Upload
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton variant="gradient" color="success" onClick={handleSave}>
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            )}
          </MDBox>
        ),
      }),
  };
}
