import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Checkbox, Tooltip, Collapse } from "@mui/material";
import PreviewDialog from "./data/PreviewDialog";
import DataTable from "examples/Tables/DataTable";

function EditDuplicateRecords({ title, duplicationResults, setDuplicationResults }) {
  const [selectedRecords, setSelectedRecords] = useState({});

  useEffect(() => {
    const initialSelectedRecords = {};
    duplicationResults.forEach((item) => {
      initialSelectedRecords[item["Student / Learner ID"]] = {
        keepNew: item.keepNew,
        keepExisting: item.keepExisting,
        // keepBoth: item.keepBoth,
      };
    });
    setSelectedRecords(initialSelectedRecords);
  }, [duplicationResults]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewGroup, setPreviewGroup] = useState(null);
  const [previewKey, setPreviewKey] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapsed(false);
    }, 1800);

    return () => clearTimeout(timer);
  }, []);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (key, field, value) => {
    // let keepBoth = false;
    let keepExisting = true;
    let keepNew = false;
    // if (field === "keepBoth") {
    //   keepBoth = value;
    //   keepExisting = value;
    //   keepNew = value;
    //   if (!value) {
    //     keepExisting = true;
    //   }
    // } else
    if (field === "keepNew") {
      // keepBoth = false;
      // keepExisting = !value;
      keepNew = value;
    } else if (field === "keepExisting") {
      // keepBoth = false;
      keepExisting = value;
      keepNew = !value;
    }

    setSelectedRecords((prev) => {
      const updatedRecords = { ...prev };
      duplicationResults.forEach((item) => {
        if (item["Student / Learner ID"] === key) {
          updatedRecords[item["Student / Learner ID"]] = {
            ...updatedRecords[item["Student / Learner ID"]],
            // keepBoth,
            keepExisting,
            keepNew,
          };
        }
      });
      return updatedRecords;
    });

    setDuplicationResults((prev) =>
      prev.map((item) => {
        if (item["Student / Learner ID"] === key) {
          // const updatedItem = { ...item, keepBoth, keepExisting, keepNew };
          const updatedItem = { ...item, keepExisting, keepNew };
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleAllCheckboxChange = (field, value) => {
    const newSelectedRecords = {};
    // let keepBoth = false;
    let keepExisting = true;
    let keepNew = false;
    // if (field === "keepBoth") {
    //   keepBoth = value;
    //   keepExisting = value;
    //   keepNew = value;
    //   if (!value) {
    //     keepExisting = true;
    //   }
    // } else
    if (field === "keepNew") {
      // keepBoth = false;
      // keepExisting = !value;
      keepNew = value;
    } else if (field === "keepExisting") {
      // keepBoth = false;
      keepExisting = value;
      keepNew = !value;
    }
    const newDuplicationResults = duplicationResults.map((item) => {
      newSelectedRecords[item["Student / Learner ID"]] = {
        ...newSelectedRecords[item["Student / Learner ID"]],
        keepExisting,
        keepNew,
        // keepBoth,
      };
      // return { ...item, [field]: value, keepExisting, keepNew, keepBoth };
      return { ...item, [field]: value, keepExisting, keepNew };
    });
    setSelectedRecords(newSelectedRecords);
    setDuplicationResults(newDuplicationResults);
  };

  const groupedResults = duplicationResults.reduce((acc, result) => {
    const key = result["Student / Learner ID"];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(result);
    return acc;
  }, {});

  const filteredGroupedResults = Object.fromEntries(
    Object.entries(groupedResults).filter(
      ([_, group]) =>
        group.some((item) => item.source === "es") && group.some((item) => item.source === "upload")
    )
  );

  const handlePreview = (key, group) => {
    setPreviewKey(key);
    setPreviewGroup(group);
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewGroup(null);
    setPreviewKey(null);
  };

  const handleExistingChange = () => {
    const keepExisting = true;
    const keepNew = false;
    // const keepBoth = false;
    setSelectedRecords((prev) => ({
      ...prev,
      [previewKey]: {
        ...prev[previewKey],
        keepExisting,
        keepNew,
        // keepBoth,
      },
    }));
    setDuplicationResults((prev) =>
      prev.map((item) =>
        item["Student / Learner ID"] === previewKey
          ? // ? { ...item, keepExisting, keepNew, keepBoth }
            { ...item, keepExisting, keepNew }
          : item
      )
    );
    handleClosePreview();
  };

  // const handleBothDataChange = () => {
  //   const keepExisting = true;
  //   const keepNew = true;
  //   const keepBoth = true;
  //   setSelectedRecords((prev) => ({
  //     ...prev,
  //     [previewKey]: {
  //       ...prev[previewKey],
  //       keepExisting,
  //       keepNew,
  //       keepBoth,
  //     },
  //   }));
  //   setDuplicationResults((prev) =>
  //     prev.map((item) =>
  //       item["Student / Learner ID"] === previewKey
  //         ? { ...item, keepExisting, keepNew, keepBoth }
  //         : item
  //     )
  //   );
  //   handleClosePreview();
  // };

  const handleKeepNewChange = () => {
    const keepNew = true;
    const keepExisting = true;
    // const keepBoth = false;
    setSelectedRecords((prev) => ({
      ...prev,
      [previewKey]: {
        ...prev[previewKey],
        keepNew,
        keepExisting,
        // keepBoth,
      },
    }));
    setDuplicationResults((prev) =>
      prev.map((item) =>
        item["Student / Learner ID"] === previewKey
          ? // ? { ...item, keepExisting, keepNew, keepBoth }
            { ...item, keepExisting, keepNew }
          : item
      )
    );
    handleClosePreview();
  };

  const previewColumns = [
    {
      Header: (
        <MDTypography variant="h6" fontWeight="bold">
          Field
        </MDTypography>
      ),
      accessor: "field",
      width: "20%",
      align: "left",
    },
    ...(previewGroup
      ? previewGroup
          .filter((item) => item.source === "es")
          .map((_, index) => ({
            Header: (
              <MDTypography variant="h6" fontWeight="bold">
                Existing Data {index + 1}
              </MDTypography>
            ),
            accessor: `existingData${index + 1}`,
            width: "20%",
            align: "left",
          }))
      : []),
    {
      Header: (
        <MDTypography variant="h6" fontWeight="bold">
          New Data
        </MDTypography>
      ),
      accessor: "newData",
      width: "20%",
      align: "left",
    },
  ];

  const previewRows = previewGroup
    ? Object.keys(previewGroup[0])
        .filter(
          (field) =>
            field !== "source" &&
            field !== "keepNew" &&
            field !== "keepExisting" &&
            field !== "keepBoth" &&
            field !== "_id"
        )
        .map((field) => {
          const row = {
            field: (
              <MDTypography variant="h6" fontWeight="bold">
                {field}
              </MDTypography>
            ),
          };
          previewGroup
            .filter((item) => item.source === "es")
            .forEach((item, index) => {
              row[`existingData${index + 1}`] = (
                <Tooltip title={item[field]}>
                  <span
                    style={{
                      display: "block",
                      width: "400px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item[field]}
                  </span>
                </Tooltip>
              );
            });
          row.newData = (
            <Tooltip title={previewGroup.find((item) => item.source === "upload")[field]}>
              <span
                style={{
                  display: "block",
                  width: "400px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {previewGroup.find((item) => item.source === "upload")[field]}
              </span>
            </Tooltip>
          );
          return row;
        })
    : [];

  return (
    <MDBox mt={2} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="light"
              onClick={handleCollapseToggle}
              style={{ cursor: "pointer" }}
            >
              <MDTypography variant="h5" fontWeight="bold">
                {title}
              </MDTypography>
            </MDBox>
            <Collapse in={!isCollapsed}>
              <DataTable
                table={{
                  columns: [
                    {
                      Header: (
                        <MDTypography variant="h6" fontWeight="bold">
                          Record
                        </MDTypography>
                      ),
                      accessor: "record",
                      width: "30%",
                      align: "left",
                    },
                    {
                      Header: (
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Grid item>
                            <MDTypography variant="h6" fontWeight="bold" align="center">
                              Keep Existing
                            </MDTypography>
                          </Grid>
                          <Grid item>
                            <MDBox
                              sx={{
                                backgroundColor: "#cccccc",
                                padding: "2px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Checkbox
                                checked={
                                  Object.keys(selectedRecords).length > 0 &&
                                  Object.values(selectedRecords).every((val) => val.keepExisting)
                                }
                                disabled={true}
                                onChange={(e) =>
                                  handleAllCheckboxChange("keepExisting", e.target.checked)
                                }
                                sx={{
                                  color: "primary.main",
                                  "&.Mui-checked": {
                                    color: "primary.main",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                  },
                                  "& .MuiCheckbox-root": {
                                    border: "2px solid",
                                  },
                                }}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      ),
                      accessor: "keepExisting",
                      width: "15%",
                      align: "center",
                    },
                    {
                      Header: (
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Grid item>
                            <MDTypography variant="h6" fontWeight="bold" align="center">
                              Keep New
                            </MDTypography>
                          </Grid>
                          <Grid item>
                            <MDBox
                              sx={{
                                backgroundColor: "#cccccc",
                                padding: "2px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Checkbox
                                checked={
                                  Object.keys(selectedRecords).length > 0 &&
                                  Object.values(selectedRecords).every((val) => val.keepNew)
                                }
                                onChange={(e) =>
                                  handleAllCheckboxChange("keepNew", e.target.checked)
                                }
                                sx={{
                                  color: "primary.main",
                                  "&.Mui-checked": {
                                    color: "primary.main",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                  },
                                  "& .MuiCheckbox-root": {
                                    border: "2px solid",
                                  },
                                }}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      ),
                      accessor: "keepNewUploadData",
                      width: "15%",
                      align: "center",
                    },
                    // {
                    //   Header: (
                    //     <Grid
                    //       container
                    //       direction="column"
                    //       alignItems="center"
                    //       justifyContent="center"
                    //       spacing={1}
                    //     >
                    //       <Grid item>
                    //         <MDTypography variant="h6" fontWeight="bold" align="center">
                    //           Keep Both
                    //         </MDTypography>
                    //       </Grid>
                    //       <Grid item>
                    //         <MDBox
                    //           sx={{
                    //             backgroundColor: "#cccccc",
                    //             padding: "2px",
                    //             display: "flex",
                    //             justifyContent:"center",
                    //           }}
                    //         >
                    //           <Checkbox
                    //             checked={
                    //               Object.keys(selectedRecords).length > 0 &&
                    //               Object.values(selectedRecords).every((val) => val.keepBoth)
                    //             }
                    //             onChange={(e) =>
                    //               handleAllCheckboxChange("keepBoth", e.target.checked)
                    //             }
                    //             sx={{
                    //               color: "primary.main",
                    //               "&.Mui-checked": {
                    //                 color: "primary.main",
                    //               },
                    //               "& .MuiSvgIcon-root": {
                    //                 fontSize: 28,
                    //               },
                    //               "& .MuiCheckbox-root": {
                    //                 border: "2px solid",
                    //               },
                    //             }}
                    //           />
                    //         </MDBox>
                    //       </Grid>
                    //     </Grid>
                    //   ),
                    //   accessor: "keepBoth",
                    //   width: "15%",
                    //   align: "center",
                    // },
                    {
                      Header: (
                        <MDTypography variant="h6" fontWeight="bold">
                          Preview
                        </MDTypography>
                      ),
                      accessor: "preview",
                      width: "25%",
                      align: "center",
                    },
                  ],
                  rows: Object.keys(filteredGroupedResults || {}).map((key) => {
                    const record = filteredGroupedResults[key][0];
                    return {
                      record: (
                        <div>
                          <strong>Student / Learner ID:</strong> {record["Student / Learner ID"]}
                          <br />
                          {/* <strong>Case No:</strong> {record["Linked to Case ID"]}
                                <br />
                                <strong>Appointment Date:</strong> {record["Appointment Date"]}
                                <br /> */}
                          {/* Add more fields as needed */}
                        </div>
                      ),
                      keepExisting: (
                        <MDBox
                          sx={{
                            backgroundColor: "#cccccc",
                            padding: "2px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Checkbox
                            checked={selectedRecords[key]?.keepExisting || false}
                            disabled={true}
                            onChange={(e) =>
                              handleCheckboxChange(key, "keepExisting", e.target.checked)
                            }
                            sx={{
                              color: "primary.main",
                              "&.Mui-checked": {
                                color: "primary.main",
                              },
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                              },
                              "& .MuiCheckbox-root": {
                                border: "2px solid",
                              },
                            }}
                          />
                        </MDBox>
                      ),
                      keepNewUploadData: (
                        <MDBox sx={{ backgroundColor: "#cccccc" }}>
                          <Checkbox
                            checked={selectedRecords[key]?.keepNew || false}
                            onChange={(e) => handleCheckboxChange(key, "keepNew", e.target.checked)}
                            sx={{
                              color: "primary.main",
                              "&.Mui-checked": {
                                color: "primary.main",
                              },
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                              },
                              "& .MuiCheckbox-root": {
                                border: "2px solid",
                              },
                            }}
                          />
                        </MDBox>
                      ),
                      // keepBoth: (
                      //   <MDBox sx={{ backgroundColor: "#cccccc" }}>
                      //     <Checkbox
                      //       checked={selectedRecords[key]?.keepBoth || false}
                      //       onChange={(e) => handleCheckboxChange(key, "keepBoth", e.target.checked)}
                      //       sx={{
                      //         color: "primary.main",
                      //         "&.Mui-checked": {
                      //           color: "primary.main",
                      //         },
                      //         "& .MuiSvgIcon-root": {
                      //           fontSize: 28,
                      //         },
                      //         "& .MuiCheckbox-root": {
                      //           border: "2px solid",
                      //         },
                      //       }}
                      //     />
                      //   </MDBox>
                      // ),
                      preview: (
                        <MDButton
                          variant="contained"
                          color="warning"
                          onClick={() => handlePreview(key, filteredGroupedResults[key])}
                        >
                          Preview
                        </MDButton>
                      ),
                    };
                  }),
                }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
              />

              <PreviewDialog
                open={previewOpen}
                group={previewGroup}
                handleClose={handleClosePreview}
                previewKey={previewKey}
                previewRows={previewRows}
                previewColumns={previewColumns}
                handleKeepNewChange={handleKeepNewChange}
                handleExistingChange={handleExistingChange}
                // handleBothDataChange={handleBothDataChange}
              />
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default EditDuplicateRecords;
