import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  fetchSignedUrl,
  uploadOnPreSignedUrl,
  submitMissingData,
  getSubmissions,
  getValidationResults,
  getDuplicationResults,
} from "store/sevres";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "examples/Tables/DataTable"; // Import DataTable component
import submissionsDataTable from "./data/submissionsDataTable";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import * as XLSX from "xlsx";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  recognisedColumns,
  recognisedKeyword,
  compulsoryColumns,
  compulsoryKeywords,
  dateColumns,
} from "../../utils/constants";
import {
  s2ab,
  handleDateOrDateTime,
  calculateAgeRange,
  hashValue,
} from "../../utils/helperFunctions";

function Upload() {
  const userAttributes = useSelector((state) => state.auth.userAttributes);
  const {
    isUploading,
    isFetchingSubmissions,
    isFetchingDuplicationResults,
    isSubmittingMissingData,
    isFetchingValidationResults,
    isSubmittingDuplicateResolution,
    submissions,
  } = useSelector((state) => state.sevres);

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const [dateTimeAttach, setDateTimeAttach] = useState(new Date().toLocaleString());
  const [error, setError] = useState("Error");
  const [errorSB, setErrorSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userProvidedKey, setUserProvidedKey] = useState("");
  const [files, setFiles] = useState([]);

  const encryptionEnabled = userAttributes ? userAttributes["custom:secureData"] === "true" : false; // Configuration boolean to enable/disable encryption

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="File Attachment Success"
      content="File is valid to proceed. Click 'Upload and Process File' button."
      open={infoSB}
      dateTime={dateTimeAttach}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="File Attachment Error"
      content={error}
      open={errorSB}
      dateTime={dateTimeAttach}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  useEffect(() => {
    dispatch(getSubmissions());
    const interval = setInterval(() => {
      dispatch(getSubmissions());
    }, 60000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [dispatch]);

  const handleDrop = (acceptedFiles) => {
    setDateTimeAttach(new Date().toLocaleString());
    if (acceptedFiles.length > 0) {
      setFiles(acceptedFiles);
      if (!acceptedFiles[0]) {
        setError("No file selected.");
        openErrorSB(true);
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const fileData = event.target.result;

        try {
          // Parse the workbook
          const workbook = XLSX.read(fileData, {
            type: "binary",
          });

          // Retain metadata like sheet names
          const sheetNames = workbook.SheetNames;

          // Process the first sheet (or loop through sheets if needed)
          const sheet = workbook.Sheets[sheetNames[0]];
          const rawData = XLSX.utils.sheet_to_json(sheet, {
            defval: "", // Preserve empty cells
            cellDates: true, // Parse dates as JavaScript Date objects
          });

          // Get column headers
          const headers = Object.keys(rawData[0]);

          // List of recognised columns missing from headers (exact match only)
          const missingFullMatch = compulsoryColumns.filter((col) => {
            // Only return columns that are NOT exactly present in headers
            return !headers.some((header) => header === col);
          });

          // List of recognised keywords missing in headers (case-insensitive partial match)
          const missingKeywordMatch = compulsoryKeywords.filter((keyword) => {
            // Check if no header contains the keyword (case-insensitive match)
            return !headers.some((header) => header.toLowerCase().includes(keyword.toLowerCase()));
          });
          const missingColumns = missingFullMatch.concat(missingKeywordMatch);
          if (missingColumns.length > 0) {
            setError(`Missing compulsory columns: ${missingColumns.join(", ")}`);
            openErrorSB(true);
            setSelectedFile(null);
            setFiles([]);
            return;
          }

          // Filter rows to keep only recognised columns and handle dates
          const filteredData = await Promise.all(
            rawData.map(async (row) =>
              Object.keys(row).reduce(async (accPromise, col) => {
                const acc = await accPromise;
                const isRecognisedColumn =
                  recognisedColumns.includes(col) ||
                  recognisedKeyword.some((keyword) =>
                    col.toLowerCase().includes(keyword.toLowerCase())
                  );
                if (isRecognisedColumn) {
                  // Handle date of birth specifically (Excel integer format)
                  if (col.toLowerCase() === "Student/Learner's Date of Birth".toLowerCase()) {
                    acc["ageRange"] = calculateAgeRange(row[col]); // Convert Excel integer DOB to age range
                  }
                  // Handle other date columns
                  else if (dateColumns.includes(col)) {
                    acc[col] = handleDateOrDateTime(row[col]); // Format dates or date-times
                  }
                  // Handle ID columns if encryption is enabled
                  else if (
                    encryptionEnabled &&
                    col.toLowerCase().includes("Student/Learner ID".toLowerCase())
                  ) {
                    acc[col] = await hashValue(row[col]);
                  } else {
                    acc[col] = row[col]; // Retain column values
                  }
                }
                return acc;
              }, Promise.resolve({}))
            )
          );

          // Create a new workbook with the filtered data
          const newWorkbook = XLSX.utils.book_new();
          const newWorksheet = XLSX.utils.json_to_sheet(filteredData);
          XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Filtered Data");

          // Generate a Blob for the new file
          const workbookBinary = XLSX.write(newWorkbook, { bookType: "xlsx", type: "binary" });
          const blob = new Blob([s2ab(workbookBinary)], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], acceptedFiles[0].name + ".xlsx", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = window.URL.createObjectURL(newFile);
          const link = document.createElement("a");
          link.href = url;
          link.download = newFile.name; // Use the file name from the modified File object
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          // Update the file state with filtered data
          setSelectedFile(newFile);
          openInfoSB(true);
          setError("");
        } catch (err) {
          setError(
            "Failed to parse file. Please ensure it is a valid CSV, XLS, or XLSX file.",
            err
          );
          openErrorSB(true);
          setSelectedFile(null);
          setFiles([]);
        }
      };
      if (
        acceptedFiles[0].name.endsWith(".xls") ||
        acceptedFiles[0].name.endsWith(".xlsx") ||
        acceptedFiles[0].name.endsWith(".csv")
      ) {
        reader.readAsBinaryString(acceptedFiles[0]);
      } else {
        setError("Unsupported file type. Please upload a CSV, XLS, or XLSX file.");
        openErrorSB(true);

        setSelectedFile(null);
        setFiles([]);
      }
      // setSelectedFile(acceptedFiles[0]);
    }
  };

  const handleUserKeyChange = (event) => {
    setUserProvidedKey(event.target.value);
    setSelectedFile(null);
    setFiles([]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = {
        filename: selectedFile.name,
        type: selectedFile.type,
        institution: userAttributes["custom:institute"],
      };
      await dispatch(fetchSignedUrl(formData));
      await dispatch(uploadOnPreSignedUrl(selectedFile));
      setSelectedFile(null);
      setFiles([]);
      setTimeout(handleRefresh, 5000);
    }
  };

  const handleRefresh = () => {
    dispatch(getSubmissions());
  };

  const handleRowClick = async (fileId) => {
    await dispatch(getValidationResults({ fileId }));
    navigate(`/upload/edit/${fileId}`);
  };

  const handleRowClickDuplicate = async (fileId) => {
    await dispatch(getDuplicationResults({ fileId }));
    navigate(`/upload/duplicate/${fileId}`);
  };

  const handlePushClick = async (fileId) => {
    const formData = []; // Create an empty formData
    const strippedFormData = {
      fileId: fileId,
      data: formData,
    };
    await dispatch(submitMissingData(strippedFormData));
    navigate(`/upload`);
  };

  const handleAcknowledgementChange = (event) => {
    setAcknowledged(event.target.checked);
  };

  const submissionsTableData = useMemo(
    () =>
      submissionsDataTable(submissions, handleRowClick, handlePushClick, handleRowClickDuplicate),
    [submissions]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
              >
                <MDTypography variant="h6" color="white">
                  Upload File
                </MDTypography>
              </MDBox>

              <MDBox p={2}>
                <Grid container spacing={3} justifyContent="center">
                  {encryptionEnabled && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={10}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MDBox width="100%">
                        {/* <MDTypography variant="h6" color="text">
                          Encryption Secret Key <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                          type="text"
                          value={userProvidedKey}
                          onChange={handleUserKeyChange}
                          required
                        /> */}
                        <MDTypography ml={2} variant="p" color="error">
                          Encryption is enabled. Please ensure the file contains a column with the header
                          &quot;Student/Learner ID&quot;.
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={10}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {isUploading ? (
                      <CircularProgress />
                    ) : (
                      <MDDropzone
                        onDrop={handleDrop}
                        files={files}
                      />
                    )}
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox>
                <Grid container spacing={3} justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={10}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MDBox>
                      <Checkbox
                        checked={acknowledged}
                        onChange={handleAcknowledgementChange}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border: "2px solid black", // Add a border
                            borderRadius: "6px", // Optional: make it rounded
                          },
                        }}
                      />
                      <MDTypography variant="p" color="error">
                        I acknowledge compliance with data submission requirements.
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox p={2}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={handleUpload}
                      disabled={
                        isUploading || !acknowledged
                      }
                    >
                      Upload and Process File
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Submissions
                </MDTypography>
                <IconButton onClick={handleRefresh} color="inherit">
                  <RefreshIcon />
                </IconButton>
              </MDBox>
              <MDBox p={2}>
                {isFetchingSubmissions ||
                isFetchingDuplicationResults ||
                isSubmittingMissingData ||
                isSubmittingDuplicateResolution ||
                isFetchingValidationResults ? (
                  <CircularProgress />
                ) : (
                  <DataTable
                    table={submissionsTableData}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    hover
                  />
                )}
              </MDBox>
              <Grid item xs={12} sm={6} lg={3}>
                {renderErrorSB}
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                {renderInfoSB}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Upload;
