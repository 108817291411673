/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchAuthSession } from "@aws-amplify/auth";

import { useSelector } from "react-redux";
// import { fetchSignedUrl } from "store/sevres";
// import MDTypography from "components/MDTypography";

function Dashboard() {
  const { userAttributes } = useSelector((state) => state.auth);
  const dashboardId = userAttributes ? userAttributes["custom:dashboardId"] : "";

  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    const checkSignInStatus = async () => {
      try {
        const session = await fetchAuthSession();
        setSignedIn(session?.tokens ? true: false);
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    checkSignInStatus();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {signedIn ? (
        <MDBox py={3}>
          <iframe
            src={`https://kibana.sevres.co.uk/app/dashboards?auth_provider_hint=anonymous1#/view/${dashboardId}?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-15M%2Cto%3Anow))&show-time-filter=true`}
            height="850"
            width="100%"
          ></iframe>
        </MDBox>
      ) : (
        <MDBox py={3}>
          <MDTypography variant="h5" fontWeight="medium" color="text">
            You must be logged in to view this dashboard.
          </MDTypography>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
