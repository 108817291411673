import http from "../services/httpService";
import axios from "axios";

export const FETCH_PRESINGED_URL = `/getPreSignedUrl`;
export const FETCH_VALIDATION_RESULTS = `/fetchMissingValues`;
export const FETCH_DUPLICATION_RESULTS = `/fetchDuplicateData`;
export const FETCH_SUBMISSIONS = `/fetchSubmissions`;
export const SUBMIT_MISSING_DATA = `/submitMissingData`;
export const SAVE_MISSING_DATA = `/saveMissingData`;
export const SUBMIT_DUPLICATES_RESOLUTION_DATA = `/submitDuplicateResolution`;

export function fetchPresignedURL(formData) {
  return http.post(FETCH_PRESINGED_URL, formData);
}

export const uploadOnPresignedURL = async (file, presignedUrl) => {
  const axiosForUpload = axios.create();
  const response = await axiosForUpload.put(
    presignedUrl,
    file,
    {
      headers: {
        "Content-Type": file.type,
      },
    }
  );
  return response;
};

export function getValidationResults(formData) {
  return http.post(FETCH_VALIDATION_RESULTS, formData);
}

export function getDuplicationResults(formData) {
  return http.post(FETCH_DUPLICATION_RESULTS, formData);
}

export function getSubmissions() {
  return http.get(FETCH_SUBMISSIONS);
}

export function submitMissingData(formData) {
  return http.post(SUBMIT_MISSING_DATA, formData);
}

export function saveMissingData(formData) {
  return http.post(SAVE_MISSING_DATA, formData);
}

export function submitDuplicatesResolution(formData) {
  return http.post(SUBMIT_DUPLICATES_RESOLUTION_DATA, formData);
}