// PreviewDialog.js
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function PreviewDialog({
  open,
  group,
  previewRows,
  previewColumns,
  handleClose,
  handleExistingChange,
  // handleBothDataChange,
  handleKeepNewChange,
}) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Preview Data</DialogTitle>
      <DialogContent>
        {group && (
          <div>
            <div>
              <strong>Student / Learner ID:</strong> {group[0]["Student / Learner ID"]}
              <br />
              {/* <strong>Case No:</strong> {group[0]["Linked to Case ID"]}
                <br />
                <strong>Appointment Date:</strong> {group[0]["Appointment Date"]} */}
              <br />
            </div>
            <DataTable
              table={{ columns: previewColumns, rows: previewRows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
            />
            <>
              <MDBox display="flex" justifyContent="center" mt={3}>
                {handleExistingChange && (
                  <MDButton
                    variant="contained"
                    color="success"
                    onClick={() => handleExistingChange()}
                    sx={{ ml: 2 }}
                  >
                    Keep Existing Data
                  </MDButton>
                )}
                {handleKeepNewChange && (
                  <MDButton
                    variant="contained"
                    color="error"
                    onClick={() => handleKeepNewChange()}
                    sx={{ ml: 2 }}
                  >
                    Keep New Data
                  </MDButton>
                )}
                {/* {handleBothDataChange && (
                  <MDButton
                    variant="contained"
                    color="info"
                    onClick={() => handleBothDataChange()}
                    sx={{ ml: 2 }}
                  >
                    Keep Both Data
                  </MDButton>
                )} */}
              </MDBox>
            </>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewDialog;
