import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Checkbox, Tooltip, Collapse } from "@mui/material";
import PreviewDialog from "./data/PreviewDialog";
import DataTable from "examples/Tables/DataTable";

function EditDuplicateRows({ title, duplicationResults, setDuplicationResults }) {
  const [selectedRecords, setSelectedRecords] = useState({});

  useEffect(() => {
    const initialSelectedRecords = {};
    duplicationResults.forEach((item) => {
      initialSelectedRecords[item["_id"]] = {
        keep: item.keep,
      };
    });
    setSelectedRecords(initialSelectedRecords);
  }, [duplicationResults]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewGroup, setPreviewGroup] = useState(null);
  const [previewKey, setPreviewKey] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapsed(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (key, field, value) => {
    const keep = value;
    setSelectedRecords((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        keep,
      },
    }));
    setDuplicationResults((prev) =>
      prev.map((item) => (item["_id"] === key ? { ...item, keep } : item))
    );
  };

  const groupedResults = duplicationResults.reduce((acc, result) => {
    const key = result["Student / Learner ID"];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(result);
    return acc;
  }, {});

  const handlePreview = (key, group) => {
    setPreviewKey(key);
    setPreviewGroup(group);
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewGroup(null);
    setPreviewKey(null);
  };

  const previewColumns = [
    {
      Header: (
        <MDTypography variant="h6" fontWeight="bold">
          Field
        </MDTypography>
      ),
      accessor: "field",
      width: "20%",
      align: "left",
    },
    ...(previewGroup
      ? previewGroup.map((item, index) => ({
          Header: (
            <Grid
              container
              direction="row"
              // alignItems="center"
              // justifyContent="center"
              spacing={1}
            >
              <Grid item>
                <MDTypography variant="h6" fontWeight="bold">
                  Row {index + 1}
                </MDTypography>
              </Grid>
              <Grid item>
                <Checkbox
                  checked={selectedRecords[item["_id"]]?.keep || false}
                  onChange={(e) =>
                    handleCheckboxChange(item["_id"], `row${index + 1}`, e.target.checked)
                  }
                  sx={{
                    color: "primary.main",
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 28,
                    },
                    "& .MuiCheckbox-root": {
                      border: "2px solid",
                    },
                  }}
                />
              </Grid>
            </Grid>
          ),
          accessor: `row${index + 1}`,
          width: "20%",
          align: "left",
        }))
      : []),
  ];

  const previewRows = previewGroup
    ? Object.keys(previewGroup[0])
        .filter(
          (field) =>
            field !== "keep" &&
            field !== "_id" &&
            field !== "unique_id" &&
            field !== "Institution" &&
            field !== "Institution Type"
        )
        .map((field) => {
          const row = {
            field: (
              <MDTypography variant="h6" fontWeight="bold">
                {field}
              </MDTypography>
            ),
          };
          previewGroup.forEach((item, index) => {
            row[`row${index + 1}`] = (
              <Tooltip title={item[field]}>
                <span
                  style={{
                    display: "block",
                    width: "400px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item[field]}
                </span>
              </Tooltip>
            );
          });
          return row;
        })
    : [];

  const maxGroupSize = Math.max(...Object.values(groupedResults).map(group => group.length));

  return (
    <MDBox mt={2} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="light"
              onClick={handleCollapseToggle}
              style={{ cursor: "pointer" }}
            >
              <MDTypography variant="h5" fontWeight="bold">
                {title}
              </MDTypography>
            </MDBox>
            <Collapse in={!isCollapsed}>
              <DataTable
                table={{
                  columns: [
                    {
                      Header: (
                        <MDTypography variant="h6" fontWeight="bold">
                          Record
                        </MDTypography>
                      ),
                      accessor: "record",
                      width: "30%",
                      align: "left",
                    },
                    ...Array.from({ length: maxGroupSize }, (_, index) => ({
                      Header: (
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Grid item>
                            <MDTypography variant="h6" fontWeight="bold" align="center">
                              Row {index + 1}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      ),
                      accessor: `row${index + 1}`,
                      width: "15%",
                      align: "center",
                    })),
                    {
                      Header: (
                        <MDTypography variant="h6" fontWeight="bold">
                          Preview
                        </MDTypography>
                      ),
                      accessor: "preview",
                      width: "25%",
                      align: "center",
                    },
                  ],
                  rows: Object.keys(groupedResults || {}).map((key) => {
                    const record = groupedResults[key][0];
                    return {
                      record: (
                        <div>
                          <strong>Student / Learner ID:</strong> {record["Student / Learner ID"]}
                          <br />
                        </div>
                      ),
                      ...groupedResults[key].reduce((acc, item, index) => {
                        acc[`row${index + 1}`] = (
                          <MDBox sx={{ backgroundColor: "#cccccc" }}>
                            <Checkbox
                              checked={selectedRecords[item["_id"]]?.keep || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  item["_id"],
                                  `row${index + 1}`,
                                  e.target.checked
                                )
                              }
                              sx={{
                                color: "primary.main",
                                "&.Mui-checked": {
                                  color: "primary.main",
                                },
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                                "& .MuiCheckbox-root": {
                                  border: "2px solid",
                                },
                              }}
                            />
                          </MDBox>
                        );
                        return acc;
                      }, {}),
                      preview: (
                        <MDButton
                          variant="contained"
                          color="warning"
                          onClick={() => handlePreview(key, groupedResults[key])}
                        >
                          Preview
                        </MDButton>
                      ),
                    };
                  }),
                }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
              />

              <PreviewDialog
                headerCheckbox={false}
                open={previewOpen}
                group={previewGroup}
                handleClose={handleClosePreview}
                previewKey={previewKey}
                previewRows={previewRows}
                previewColumns={previewColumns}
                handleKeepNewChange={null}
                handleExistingChange={null}
                handleBothDataChange={null}
              />
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default EditDuplicateRows;
